import React from "react";
import LineChartComponent from "../charts/LineChartComponent";
import CustomButton from "../common/Button";

const RecordItem = ({record}) => {
    const [open, setOpen] = React.useState(false);
    return (
        <div className="card">
            <div className="card-header" id="headingOne">
                <h2 className="mb-0">
                    <div className="card-head-row">
                        <div className="card-col">
                            <div className="crd-name-reg-panel">
                                <div className="user-nm-panel">{record?.name}</div>
                                <div className="reg-panel">{record?.reg_no}</div>
                            </div>
                        </div>
                        <div className="card-col text-center">
                            <div className="days-home-panel">
                                <div className="days-home-count-panel">{record?.days_at_home}</div>
                            </div>
                        </div>
                        <div className="card-col text-center">
                            <div
                                className={"alert-sign alert " + (record && record.alert_class ? `alert-${record.alert_class}` : "alert-info") + " "}>
                                <div className="alert-oval">!</div>
                            </div>
                        </div>
                        <div className="card-col text-center">
                            <div className="confusion-pen">{record?.conciousness}</div>
                        </div>
                        <div className="card-col text-center">
                            <div className="heart-rate-panel">
                                <div className="count">{record?.heart_rate}</div>
                                <div className="mins-ago">{record?.heart_rate_when}</div>
                                <div className={record?.heart_rate_badge}></div>
                            </div>
                        </div>
                        <div className="card-col text-center">
                            <div className="heart-rate-panel">
                                <div className="count">{record?.bp_rate}</div>
                                <div className="mins-ago">{record?.bp_rate_when}</div>
                                <div className={record?.bp_rate_badge}></div>
                            </div>
                        </div>
                        <div className="card-col text-center">
                            <div className="heart-rate-panel">
                                <div className="count">{record?.spo2}</div>
                                <div className="mins-ago">{record?.sp02_when}</div>
                                <div className={record?.spo2_rate_badge}></div>
                            </div>
                        </div>
                        <div className="card-col text-center">
                            <div className="heart-rate-panel">
                                <div className="count">{record?.temp}</div>
                                <div className="mins-ago">{record?.temp_rate_when}</div>
                                <div className={record?.temp_rate_badge}></div>
                            </div>
                        </div>
                        <div className="card-col text-center">
                            <div className="heart-rate-panel">
                                <div className="count">73</div>
                                <div className="mins-ago">45 mins ago</div>
                                <div className="oval-shape-badge grey-badge"></div>
                            </div>
                        </div>
                        <div className="card-col card-col-last">
                            <div className="">
                                <CustomButton type={"button"} onClick={() => {
                                    setOpen(!open);
                                }} classVar={open ? "btn btn-sm btn-dark" : "btn btn-sm btn-outline-primary"}
                                              icon={open ? "text-white fa fas fa-eye-slash" : "text-white fa fas fa-eye"}/>
                            </div>
                        </div>
                    </div>
                </h2>
            </div>

            {
                open ?
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                         data-parent="#accordionExample">
                        <div className="card-body">
                            <div className="smart-summary-panel">
                                <div className="row">
                                    <div className="col-md-10">
                                        <div className="graph-panel mb-5 p-2">
                                            <LineChartComponent record={record}/>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="sm-summary-panel">
                                            <h3>Smart Summary</h3>
                                            {
                                                record?.summary && record?.summary.length > 0 && record.summary.map((summary, index) => {
                                                    return (
                                                        <div className="light-grey-card" key={`s{index}`}>
                                                            <small>{summary.title}</small>
                                                            <p>{summary.note}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }

        </div>
    );
}

export default RecordItem;