import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import api from "../../services/api";
import AuthLayout from "../layout/AuthLayout";
const Profile = () => {
  const { auth } = useContext(AuthContext);
  const [profile, setProfile] = useState({ name: "", email: "" });
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await api.get("/profile", {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        setProfile(response.data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    fetchProfile();
  }, [auth]);
  return (
    <AuthLayout>
      <div className="container mt-5">
        <h1>Profile Information</h1>
        <div className="card">
          <div className="card-body">
            <p>
              <strong>Name:</strong> {profile.name}
            </p>
            <p>
              <strong>Email:</strong> {profile.email}
            </p>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
export default Profile;
