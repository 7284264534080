import axios from "axios";
const api = axios.create({
  baseURL: "https://mii2-dev.laracp.com/api", // Adjust accordingly to your Laravel app URL
});
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers['X-Requested-With'] = `XMLHttpRequest`;
  return config;
});
export default api;
