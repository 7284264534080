import React, {useContext} from "react";
import avtar_photo from "../../assets/avtar-photo.jpg"
import search_icon from "../../assets/search-icon.png"
import filter_icon from "../../assets/filter-icon.png"
import not_icon from "../../assets/notification-icon.png"
import logo_index from "../../assets/logo-index.jpg"
import {Link} from "react-router-dom"
import {AuthContext} from "../../context/AuthContext";

const TopSection = (props) => {
    const {logout} = useContext(AuthContext);

    return (
        <section className="top-head-section">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-3 col-sm-3">
                        <div className="avtar-us-section">
                            <div className="avtar-photo">
                                <img src={avtar_photo} alt="avatar"/>
                            </div>
                            <div className="avtar-dt-panel">
                                <div className="name-av">
                                    <span className="hello-span">Hello</span>
                                    {" "}
                                    <span className="name-span">{props.user?.firstname + ' ' + props.user?.lastname}</span>
                                </div>
                                <div className="sub-nm-av">{props?.user?.designation}</div>
                                <button className="btn btn-danger btn-sm" onClick={logout}>
                                    Logout
                                </button>
                                <button className="btn btn-dark mx-2 btn-sm" onClick={props.onPress}>
                                    Reset Data
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="search-notification-section">
                            <div className="search-fl-col">
                                <div className="search-panel-filter">
                                    <div className="search-col">
                                        <input type="text" placeholder="Serach" className="form-control" name="search"/>
                                    </div>
                                    <div className="search-lens-col">
                                        <button className="search-keyword">
                                            <img src={search_icon} alt="search"/>
                                        </button>
                                    </div>
                                    <div className="filter-col">
                                        <button className="filter-keyword">
                                            <img src={filter_icon} alt="filter"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="notification-col">
                                <div className="dropdown">
                                    <button className="notification-btn dropdown-toggle" type="button"
                                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                        <img src={not_icon} alt="notification-icon"/>
                                        <div className="notification-alert"></div>
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a className="dropdown-item" href="#">Action</a>
                                        <a className="dropdown-item" href="#">Another action</a>
                                        <a className="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3 text-end">
                        <div className="logopowered text-right">
                            <div className="powered-title">
                                Powered by
                            </div>
                            <div className="miicare-logo">
                                <Link to="/dashboard">
                                    <img src={logo_index} alt="logo-index"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TopSection;