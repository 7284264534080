import React, { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import api from "../../services/api";
import Input from "../common/Input";
import Button from "../common/Button";
import AuthLayout from "../layout/AuthLayout";
const ChangePassword = () => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const { auth } = useContext(AuthContext);
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmPassword) {
      setError("New passwords do not match.");
      return;
    }
    try {
      const response = await api.post(
        "/change-password",
        {
          current_password: formData.currentPassword,
          new_password: formData.newPassword,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
      console.log("Password changed successfully:", response.data);
      alert("Password changed successfully");
    } catch (error) {
      setError(error.response.data.message || "Failed to change password.");
    }
  };
  return (
    <AuthLayout>
      <div className="container mt-5">
        <h1>Change Password</h1>
        <form onSubmit={handleSubmit}>
          <Input
            label="Current Password"
            type="password"
            name="currentPassword"
            value={formData.currentPassword}
            onChange={handleInputChange}
          />
          <Input
            label="New Password"
            type="password"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleInputChange}
          />
          <Input
            label="Confirm New Password"
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
          />
          {error && <div className="alert alert-danger">{error}</div>}
          <Button type="submit" text="Change Password" />
        </form>
      </div>
    </AuthLayout>
  );
};
export default ChangePassword;
