import React, {useContext, useState} from "react";
import TopSection from "../inc/TopSection";
import {AuthContext} from "../../context/AuthContext";
import api from "../../services/api";


const AuthLayout = ({children}) => {
    const {userChecked} = useContext(AuthContext);
    const [reset, setReset] = useState(false);
    const hardReset = async () => {
        if(window.confirm("Are you sure you want to reset this page?")){
            setReset(true);
            const {data} = await api.get('/reset-data');
            console.log(data);
            window.location.reload();
            setReset(false);
        } else {
            return false;
        }
    }

    return (
        <>
            <TopSection user={userChecked} onPress={() => hardReset()}></TopSection>
            <section className="detail-user-section">
                <div className="container-fluid">
                    {!reset ? children : <div className={"alert alert-info"}>Reset data on progress, please wait...</div> }
                </div>
            </section>
        </>
    )
};
export default AuthLayout;
