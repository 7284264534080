import React, { createContext, useEffect, useState } from "react";
import api from "../services/api";
export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(false);
  const [userChecked, setuserChecked] = useState(null);
  const [pass, setPass] = useState(false);
  const checkLoggedIn = async () => {
    try {
      const { data } = await api.get("/user");
      setuserChecked({...data});
      setAuth(true);
      setPass(true);
      return true;
    } catch (error) {
      setAuth(false);
      setPass(true);
      return false;
    }
  };
  const login = async (email, password) => {
    try {
      const { data } = await api.post("/login", {
        email,
        password,
      });
      setAuth(true);
      localStorage.setItem("token", data.token);
      return true;
    } catch (error) {
      console.log("ERROR: ", error);
      alert("Login failed! Error");
      return false;
    }
  };
  const logout = async () => {
    try {
      const { data } = await api.post("/logout");
      setAuth(false);
      localStorage.removeItem("token");
      return true;
    } catch (error) {
      setAuth(false);
      return false;
    }
  };

  useEffect(() => {
    checkLoggedIn();
  }, []);

  if(!pass) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ auth, login, logout, userChecked }}>
      {children}
    </AuthContext.Provider>
  );
};
