import React, {useEffect, useState} from "react";
import AuthLayout from "../layout/AuthLayout";
import filter_icon from "../../assets/filter-icon.png";
import nt_photo from "../../assets/nt-photo-img-001.jpg";
import RecordItem from "../inc/RecordItem";
import api from "../../services/api";
import {DateRangePicker} from 'react-date-range';
import moment from "moment";

const tabs = [
    "Days at home",
    "Alert",
    "Consciousness",
    "Heart Rate",
    "Blood Pressure",
    "SP02",
    "Temperature",
    "Weight Kg",
];

const subtractDaysAndFormat = (days, php) => {
    // Subtract the specified number of days from the current date
    const newDate = moment().subtract(days, 'days');

    // Format the date to PHP format
    if(php) {
        return newDate.format('YYYY-MM-DD HH:mm:ss');
    } else {
        return newDate;
    }
};
function subtractDays(days) {
    const date = new Date();

    // Subtract the specified number of days from the current date
    date.setDate(date.getDate() - days);

    // Return the date in standard JavaScript date format
    return date;
}

let calstart = subtractDays(15);
let start = subtractDaysAndFormat(15, true);
let end = new Date();
console.log("calstart: ", calstart);
console.log("dt: ", new Date());
const Dashboard = () => {
    const [records, setRecords] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);
    const [dateRange, setDateRange] = useState([
        {
            startDate: calstart,
            endDate: new Date(),
            key: 'selection'
        }
    ]);


    const formatDateForPHP = (date) => {
        // return moment(date).format('YYYY-MM-DD HH:mm:ss'); // PHP date format
        return moment(date).format('YYYY-MM-DD'); // PHP date format
    };

    const handleSelect = (ranges) => {
        setDateRange([ranges.selection]);
        start = ranges.selection.startDate;
        end = ranges.selection.endDate;
        setTimeout(() => {getRecords();}, 500);
    }


    const getRecords = async () => {
        let startD = formatDateForPHP(start);
        let endD = formatDateForPHP(end);
        const {data} = await api.get(`/get-all-consolidated-records?start=${startD}&end=${endD}`);
        setRecords([...data.records]);
        setLoaded(true);
    };

    const renderRecords = () => {
        return (
            <div className="accordion" id="accordionExample">
                {records.map((record, index) => {
                    return <RecordItem record={record} key={index}/>;
                })}
            </div>
        );
    };

    useEffect(() => {
        getRecords();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            getRecords()
        }, 5000);
        //Clearing the interval
        return () => clearInterval(interval);
    }, []);

    return (
        <AuthLayout>
            <div className="row">
                <div className="col-md-9 col-sm-9">
                    <div className="accordion-section">
                        <section className="category-head-section">
                            <div className="card-head-row">
                                <div className="card-col">&nbsp;</div>
                                {tabs.map((tab, index) => {
                                    return (
                                        <div className="card-col text-center" key={index}>
                                            <div className="category-nav-thead-center">
                        <span className="span-icon">
                          <img
                              src={require("../../assets/tab-icon-img-00" +
                                  (index + 1) +
                                  ".png")}
                              alt="tav-icon"
                          />
                        </span>
                                                <span className="span-title">{tab}</span>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="card-col">&nbsp;</div>
                            </div>
                        </section>
                        {!loaded ? (
                            <div className="alert alert-info">Loading....</div>
                        ) : !records.length ? (
                            <div className="alert alert-warning">No record found!</div>
                        ) : (
                            renderRecords()
                        )}
                    </div>
                </div>
                <div className="col-md-3 col-sm-3">
                    <div className="news-key-section">
                        <h3>NEWS Key</h3>
                        <ul>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                        </ul>
                    </div>
                    <div className="calendar-notes-section">
                        <div className="calendar-month-ch">
                            <DateRangePicker
                                ranges={dateRange}
                                onChange={handleSelect}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                direction="horizontal"
                                staticRanges={[]} // Hides predefined ranges
                                inputRanges={[]}  // Hides custom input ranges
                            />
                        </div>
                        <div className="notes-section">
                            <div className="nt-panel-row">
                                <div className="not-title">Notes</div>
                                <div className="add-notes">
                                    <a href="#">Add</a>
                                </div>
                            </div>
                            <div className="notes-bg">
                                <div className="notes-search-filter">
                                    <div className="nt-search">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control"
                                            name=""
                                        />
                                    </div>
                                    <div className="filter-botes">
                                        <a href="#">
                                            <img src={filter_icon} alt="filter"/>
                                        </a>
                                    </div>
                                </div>

                                <div className="user-notes-panel">
                                    <div className="user-notes-row">
                                        <div className="usernotes-photo">
                                            <div className="avt-note-photo">
                                                <img src={nt_photo} alt="photo"/>
                                            </div>
                                        </div>
                                        <div className="usernotes-content">
                                            <h3>Dr. Richard Wong</h3>
                                            <small>Geriatrician</small>
                                            <p>
                                                <strong>Donald Trump</strong> It is a long established
                                                fact that a reader will be distracted by the readable
                                                content of a page.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="user-notes-panel">
                                    <div className="user-notes-row">
                                        <div className="usernotes-photo">
                                            <div className="avt-note-photo">
                                                <img src={nt_photo} alt="photo"/>
                                            </div>
                                        </div>
                                        <div className="usernotes-content">
                                            <h3>Curtis Prankard</h3>
                                            <small>Medician Care</small>
                                            <p>
                                                <strong>Max William</strong> It is a long established
                                                fact that a reader will be distracted by the readable
                                                content of a page.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthLayout>
    );
};

export default Dashboard;
