import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const NonPrivateRoute = ({ children }) => {
  const { auth } = useContext(AuthContext);
  return !auth ? children : <Navigate to="/dashboard" />;
};

export default NonPrivateRoute;
