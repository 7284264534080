import React from "react";
import Button from "react-bootstrap/Button";

const   CustomButton = ({ type, onClick, text, disabled, classVar, icon }) => (
  <Button
    type={type}
    onClick={onClick}
    disabled={disabled}
    className={classVar ? classVar : "btn btn-primary w-100"}
  >
    {text}
    {
      icon ?
          <i className={icon}/>
          :
          null
    }
  </Button>
);

export default CustomButton;
