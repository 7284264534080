import React, {useContext, useEffect, useState} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import Input from "../common/Input";
import CustomButton from "../common/Button";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.min.css";
import NonAuthLayout from "../layout/NonAuthLayout";

const LoginPage = () => {
    const [email, setEmail] = useState("monica@domain.com");
    const [password, setPassword] = useState("password");
    const [loading, setLoading] = useState(false);
    const {login, auth} = useContext(AuthContext);
    const navigate = useNavigate();


    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (await login(email, password)) {
            navigate("/dashboard");
        } else {
            alert("Login failed!");
        }
        setLoading(false);
    };

    return (
        <NonAuthLayout>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{height: "100vh"}}
            >
                <Card style={{width: "400px"}}>
                    <Card.Header as="h5" className="text-center">
                        Login
                    </Card.Header>
                    <Card.Body>
                        <form onSubmit={handleLogin}>
                            <Input
                                label="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Input
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <CustomButton
                                type="submit"
                                text={
                                    loading ? (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        "Login"
                                    )
                                }
                                disabled={loading}
                            />
                        </form>
                    </Card.Body>
                </Card>
            </div>
        </NonAuthLayout>
    );
};

export default LoginPage;
