import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// Create a root.
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

// Initial render.
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
