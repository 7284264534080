import React from "react";
const NonAuthLayout = ({ children }) => (
  <div
    className="d-flex align-items-center justify-content-center"
    style={{ height: "100vh" }}
  >
    <div>{children}</div>
  </div>
);
export default NonAuthLayout;
