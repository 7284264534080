import React from "react";
import Form from "react-bootstrap/Form";

const Input = ({ label, type, value, onChange }) => (
  <Form.Group className="mb-3">
    <Form.Label>{label}</Form.Label>
    <Form.Control type={type} value={value} onChange={onChange} />
  </Form.Group>
);

export default Input;
