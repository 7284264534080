import React, {useState} from 'react';
import {Line} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Register required components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LineChartComponent = ({record}) => {
    const [selectedDatasets, setSelectedDatasets] = useState({
        daysAtHome: true,
        consciousness: true,
        heartRate: true,
        bloodPressureHigh: true,
        bloodPressureLow: true,
        spo2: true,
        temperature: true,
        weight: true
    });

    const handleCheckboxChange = (dataset) => {
        const updatedSelections = {...selectedDatasets, [dataset]: !selectedDatasets[dataset]};
        const checkedCount = Object.values(updatedSelections).filter(v => v).length;

        if (checkedCount >= 1) {
            setSelectedDatasets(updatedSelections);
        }
    };

    const data = {
        labels: record.date_range_labels,
        datasets: [
            {
                label: 'Days at Home',
                data: record.chart_days,
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgb(75, 192, 192)',
                tension: 0.1,
                hidden: !selectedDatasets.daysAtHome,
                pointStyle: 'circle'
            },
            {
                label: 'Consciousness',
                data: record.conc,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgb(255, 99, 132)',
                tension: 0.1,
                hidden: !selectedDatasets.consciousness,
                pointStyle: 'circle'
            },
            {
                label: 'Heart Rate',
                data: record.chart_hr,
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgb(54, 162, 235)',
                tension: 0.1,
                hidden: !selectedDatasets.heartRate,
                pointStyle: 'circle'
            },
            {
                label: 'BP (High)',
                data: record.chart_sbp,
                borderColor: 'rgb(153, 102, 255)',
                backgroundColor: 'rgb(153, 102, 255)',
                tension: 0.1,
                hidden: !selectedDatasets.bloodPressureHigh,
                pointStyle: 'circle'
            },
            {
                label: 'BP (Low)',
                data: record.chart_dbp,
                borderColor: 'rgb(103, 142, 111)',
                backgroundColor: 'rgb(103, 142, 111)',
                tension: 0.1,
                hidden: !selectedDatasets.bloodPressureLow,
                pointStyle: 'circle'
            },
            {
                label: 'SpO2',
                data: record.chart_spo2,
                borderColor: 'rgb(255, 159, 64)',
                backgroundColor: 'rgb(255, 159, 64)',
                tension: 0.1,
                hidden: !selectedDatasets.spo2,
                pointStyle: 'circle'
            },
            {
                label: 'Temperature',
                data: record.chart_temp,
                borderColor: 'rgb(255, 206, 86)',
                backgroundColor: 'rgb(255, 206, 86)',
                tension: 0.1,
                hidden: !selectedDatasets.temperature,
                pointStyle: 'circle'
            },
            {
                label: 'Weight',
                data: record.chart_wgt,
                borderColor: 'rgb(75, 192, 75)',
                backgroundColor: 'rgb(75, 192, 75)',
                tension: 0.1,
                hidden: !selectedDatasets.weight,
                pointStyle: 'circle',
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true,
                },
            },
        },
    };

    return (
        <div key={record.id} className="charts">
            <div className="form-group p-2 mb-5"  key={Math.random()}>
                <div className="row" key={Math.random()}>
                    <div className="col-md-2 p-2 mb-2">
                        <h6>Select Option</h6>
                        {Object.keys(selectedDatasets).map((dataset, index) => (
                            <div className="mb-2 px-2" style={{backgroundColor: data.datasets[index].backgroundColor}}   key={Math.random()}>
                                <label key={dataset} className={'d-block'}  style={{marginRight: '10px'}}  htmlFor={`option-${index}`}>
                                    <input
                                        type="checkbox"
                                        checked={selectedDatasets[dataset]}
                                        onChange={() => handleCheckboxChange(dataset)}
                                        id={`option-${index}`}
                                    />
                                    {" "}
                                    <small style={{fontSize:12}}>
                                        {/*{dataset.charAt(0).toUpperCase() + dataset.slice(1).replace(/([A-Z])/g, ' $1')}*/}
                                        {" "}
                                        {data.datasets[index].label}
                                    </small>
                                </label>
                            </div>
                        ))}
                    </div>
                    <div className="col-md-10">
                        <Line data={data} options={options}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LineChartComponent;
