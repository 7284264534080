import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {AuthProvider} from './context/AuthContext';
import PrivateRoute from './components/common/PrivateRoute';
import NonPrivateRoute from './components/common/NonPrivateRoute';
import LoginPage from './components/auth/LoginPage';
import Dashboard from './components/dashboard/Dashboard';
import Profile from './components/dashboard/Profile';
import ChangePassword from './components/dashboard/ChangePassword';

function App() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route
                        path="/login"
                        element={
                            <NonPrivateRoute>
                                <LoginPage/>
                            </NonPrivateRoute>
                        }
                    />
                    <Route
                        path="/"
                        element={
                            <PrivateRoute/>
                        }
                    >
                        <Route path="dashboard" element={<Dashboard/>}/>
                        <Route path="profile" element={<Profile/>}/>
                        <Route path="change-password" element={<ChangePassword/>}/>
                    </Route>
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;
